<template>
  <v-list-item
    v-if="updateExists"
    class="accent mt-1 mb-1"
    ripple
    @click="refreshApp"
  >
    <v-list-item-icon><v-icon>fas fa-wrench</v-icon></v-list-item-icon>
    <v-list-item>Update to newer version</v-list-item>
  </v-list-item>
</template>

<script>
export default {
  name: "update_version",
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      // console.log(event);
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false
  })
};
</script>

<style scoped></style>
